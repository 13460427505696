<template>
  <p class="h2">
    {{ playlist.name }}
    <router-link :to="{ name: 'PizzaEdit' , params: { playlistId: playlist._id }}">
      <i class="bi bi-file-earmark-plus" />
    </router-link>
  </p>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">
          Base
        </th>
        <th scope="col">
          Ingredients
        </th>
        <th scope="col">
          #
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="pizza in playlist.pizzas" :key="pizza.id">
        <td>
          <router-link :to="{ name: 'PizzaEdit', params: { id: pizza.id, playlistId: playlist._id } }">
            {{ pizza.base }}
          </router-link>
        </td>
        <td>
          {{ pizza.ingredients }}
        </td>
        <td>{{ pizza.ingredient_num }}</td>
        <td>
          <i class="bi bi-file-earmark-text" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>

import axios from "axios";

export default {
  name: "BookRezepte",
  components: {
    //HelloWorld
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
   return {
     playlist: {},
     name: "",
     list: []
   }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      try {
      
        const { data: item } = await axios.get(
          `/api/rest/dao/playlist/${this.id}/?_populate=pizzas.ingredients,pizzas.base`
        );
        this.playlist = item;
        this.playlist.pizzas = this.playlist.pizzas.map((row) => {
          const ingredients = row.ingredients.map((i) => i.name);
          return { 
            id: row._id,
            base: row.base.name,
            ingredients: ingredients.join(', '),
            ingredient_num : ingredients.length,
          }
        });
        //console.log(this.playlist);
        /*
        this.name = item.name;
        this.list = item.pizzas.map((row) => {
          const ingredients = row.ingredients.map((i) => i.name);
          return { 
            id: row._id,
            base: row.base.name,
            ingredients: ingredients.join(', '),
            ingredient_num : ingredients.length,
          }
        });
        */
      } catch (err) {
        this.errors = err.response;
      }
    },
  },
};
</script>
